body {
  @apply antialiased text-offBlack;
}

a {
  @apply hover:text-heartOrange transition-colors duration-300 group-hover:text-heartOrange;
}

.webkit-fill {
  height: 100vh;
  height: 100cqh;
}

.generalGrid {
  @apply md:grid grid-cols-12 gap-5;
}

.entryGrid {
  @apply md:grid grid-cols-12 gap-x-14 gap-y-5
}

.headerText a {
  @apply underline;
}

.inlineCaption {
  @apply text-sm 2xl:text-base pt-3 pl-1  transition-opacity duration-200 group-hover:opacity-100;
}

.imageHoverUp {
  @apply transform transition-transform duration-300 group-hover:-translate-y-2;
}

.pageTitle {
  @apply text-3xl
}

.roundedButton {
  @apply rounded-full px-5 pb-[0.2rem] pt-1.5 bg-offBlack text-lightOrange text-sm transition-colors duration-100 border-2 border-transparent;
}

.hoverButton {
  @apply hover:bg-lightOrange hover:border-offBlack hover:text-offBlack;
}

.hoverMouseTransform {
  transform:translate(-50%,-50%);
}

.aboutText p strong {
  @apply !font-normal md:text-heartOrange md:cursor-pointer
}

/* purgecss start ignore */

.twic-blur, .twic-loading {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
}

  .twic-done {
    opacity: 1;
  }


  :where(media-player[data-view-type='video']:not([data-fullscreen])) :where(media-outlet, video, media-poster, div[part='scrim']) {
    @apply rounded-[0px] border-none border-transparent;
  }

  media-player, media-outlet {
    @apply bg-transparent;
  }

/* purgecss end ignore */