@import "tailwindcss/base";
@import "./components/swiper.css";
@import "./components/vids/defaults.css";
@import "./components/vids/video.css";
@import "./typography.css";
@import "tailwindcss/components";

@import "./components/menu.css";
@import "./layout.css";



@import "tailwindcss/utilities";