@font-face {
    font-family: 'Editorial';
    font-style: normal;
    src:
        url(../fonts/PPEditorialNew-Regular.woff) format("woff"),
        url(../fonts/PPEditorialNew-Regular.woff2) format("woff2");
}

@font-face {
    font-family: 'Editorial';
    font-style: italic;
    src:
        url(../fonts/PPEditorialNew-Italic.woff) format("woff"),
        url(../fonts/PPEditorialNew-Italic.woff2) format("woff2");
}


@font-face {
    font-family: 'CaslonDoric';
    src:
        url(../fonts/CaslonDoric-RegularNo2-Trial.otf) format("opentype");
}

@font-face {
    font-family: 'CaslonDoric';
    font-style: italic;
    src:
        url(../fonts/CaslonDoric-RegularNo2Italic-Trial.otf) format("opentype");
}

@font-face {
    font-family: 'CaslonDoric';
    font-weight: 600;
    src:
        url(../fonts/CaslonDoric-Medium-Trial.otf) format("opentype");
}